/*    
 *  _________________________________________
 * / Stop right there, ya dingus. This file  \
 * \ should not be edited.                   /
 *  -----------------------------------------
 *  \
 *   \
 *      __
 *     /  \
 *     |  |
 *     @  @
 *     |  |
 *     || |/
 *     || ||
 *     |\_/|
 *     \___/
 *    
 * This file is automatically generated through https://localise.biz.
 *                                                                         
 * To update this file:
 *
 *  $ npm run update-language
 *
 * Last update:    Mon Jan 13 2025 12:18:41 GMT+0100 (Central European Standard Time)
 * Language keys:  169
 * Language code:  nl
 *
 */

const language = {
  "Login_Login": "Inloggen",
  "Login_Login_SubText": "Log in met uw gebruikersnaam en wachtwoord",
  "Login_RememberMe": "Onthoud mijn gegevens",
  "Login_Helpdesk_1": "Problemen bij het inloggen?",
  "Login_Helpdesk_2": "Neem contact op met de helpdesk.",
  "Login_SavedUsers": "Opgeslagen gebruikers",
  "Login_SavedUsers_LastLoggedIn": "Laatst ingelogd %{date} om %{time}",
  "Main_Filter": "Filter",
  "Main_Filter_External": "Zoek op Extern ID",
  "Main_Filter_Name": "Zoek op naam",
  "Main_Filter_Alarm": "Alarmniveau",
  "Main_Filter_Alarm_Red": "Rood (hoogste prioriteit)",
  "Main_Filter_Alarm_Orange": "Oranje (urgent)",
  "Main_Filter_Alarm_Green": "Groen (lage prioriteit / test)",
  "Main_Filter_Time": {
    "0": "Tijdsduur",
    "Start": "Vanaf datum",
    "End": "Tot datum"
  },
  "Main_OpenAlarms": "Actieve alarmen",
  "Main_ClosedAlarms": "Afgemelde alarmen",
  "Main_ShowMore": "Toon meer...",
  "Main_OpenAlarms_MadeBy": "Gemaakt door",
  "Main_OpenAlarms_Today": "Vandaag,",
  "Main_OpenAlarms_Company": "Bedrijfsnaam",
  "Main_By": {
    "Customer": "Door **%{alarmCreator} - %{customer}**",
    "No_Customer": "Door **%{alarmCreator}**"
  },
  "TopBar_Title": "Voor centralisten",
  "TopBar_Settings": "Instellingen",
  "TopBar_Tutorial": "Uitlegvideo",
  "TopBar_Sound_Muted": "Gedempt",
  "TopBar_Logout_Text": "Weet u zeker dat u wilt uitloggen?",
  "Main_Filter_Alarm_Test_Plural": "Groen: testalarmen",
  "Main_Filter_Alarm_Assistance_Plural": "Orange: assistentie",
  "Main_Filter_Alarm_Panic_Plural": "Rood: urgente hulpvraag",
  "Main_OpenAlarms_Yesterday": "Gisteren om",
  "Menu_Linked": "Gekoppelde klant",
  "Menu_Linked_Sub": "Je hebt toegang tot meerdere klanten. Typ om te zoeken naar een klant.",
  "Menu_LinkedLast": "Recent gekoppelde klanten",
  "Menu_LinkedLast_Sub": "Klik om snel te wisselen",
  "Menu_Language": "Taal",
  "Dates": {
    "At": "om",
    "Last": "Afgelopen",
    "Yesterday": "Gisteren",
    "Today": "Vandaag",
    "Tomorrow": "Morgen",
    "Now": "Nu"
  },
  "AlarmDetails_Extern": "Extern ID",
  "AlarmDetails_Customer": "Bedrijf/klant",
  "Address_CurrentPos": "Huidige positie",
  "Address_CurrentPos_Meter_Plural": "meter",
  "Address_CurrentPos_Meter": "meter",
  "Address_Inside_Cap": "Binnen",
  "Address_Inside": "is binnen bij",
  "Address_Outside": "is",
  "Address_AlarmPos": "Alarmpositie",
  "TopBar_Alarms": "Alarm overzicht",
  "TopBar_AlarmDetails": "Alarmdetails",
  "TopBar_Main": "X-Guard Alarm Manager",
  "Helpers_WhoHelp": "Personen die helpen",
  "AlarmMap_Map": "Kaart",
  "Protocol_Response": "Opvolgprocedure",
  "Responder_Customer_Title": "Klant",
  "Responder_QuickResponse_Title": "Mensen dichtbij",
  "Responder_Group_Customer": "Alarmbron",
  "Responder_Group_RA": "Gebied",
  "Responder_Group_PC": "Contactpersonen",
  "Responder_Group_QR": "Mensen dichtbij",
  "Responder_Group_NQR": "Mobiele surveillant",
  "Responder_Group_BasedDistance": "Gesorteerd op afstand",
  "Helpers_Tooltip": "De mensen in deze lijst hebben bevestigd dat zij reageren op het alarm. Mogelijk hebben ze een automatisch telefoongesprek ontvangen met de alarmdetails. Controleer het logboek voor de details.",
  "Helpers_None": "Nog niemand heeft aangegeven te komen helpen bij dit alarm.",
  "Error_Validation_DuplicateValue": "Dubbele waarde genegeerd",
  "Main_Filter_External_Placeholder": "Extern ID",
  "Main_Filter_Name_Placeholder": "Naam",
  "Main_Filter_Time_Start_Placeholder": "Startdatum",
  "Main_Filter_Time_End_Placeholder": "Einddatum",
  "Warning_AlarmFilter_Active": "Let op: er zijn zoekfilters actief",
  "Alarm_Detail_Log_Title": "Logboek / chat",
  "Alarm_Detail_Log_Messages": "Berichten",
  "Alarm_Detail_Log_AddMessages": "Typ een bericht",
  "Alarm_Detail_Log_AddMessagesDisabled": "Berichten toevoegen is niet meer mogelijk",
  "Alarm_Detail_Log_OpenLog": "Open logboek",
  "Alarm_Detail_Log_CloseLog": "Logboek sluiten",
  "Alarm_Detail_Log_No_Messages": "Er zijn nog geen gebeurtenissen gelogd",
  "Alarm_Detail_Log_Action_Confirmation_True": "Ik ga helpen bij dit alarm",
  "Alarm_Detail_Log_Action_Confirmation_False": "Ik ga NIET helpen bij dit alarm",
  "Alarm_Detail_Log_Action_Call": "Gebeld naar %{name}",
  "Alarm_Detail_Log_Action_Notification": "Notificatie gestuurd naar %{name}",
  "Alarm_Detail_Log_Action_Sms": "SMS gestuurd naar %{name}",
  "Alarm_Detail_Log_Action_Acknowledge": "Alarm afgemeld",
  "Alarm_Detail_Log_Action_Call_Initiated": "Mogelijk gebeld naar / klikte op bellen bij %{name}",
  "Alarm_Detail_Log_System_Escalated_AlarmCenter": "Alarm geëscaleerd naar de meldkamer.",
  "Alarm_Detail_Log_Action_AcknowledgeMessage": {
    "app": "Ik heb dit alarm afgemeld via de X-Guard Alarm-app.",
    "web": "Dit alarm is afgemeld via de X-Guard Alarm Manager"
  },
  "Alarm_Detail_Log_Action_System_AcknowledgeMessage": "Dit alarm is automatisch afgemeld",
  "Alarm_Detail_Log_Action_Manual_Notification": "Alarm gedeeld met %{name} (automatisch telefoongesprek en sms)",
  "Alarm_Detail_Log_LoadingMoreMessagesStatus": "Berichten laden...",
  "Alarm_Detail_Log_LoadingMessagesStatus": "Berichten laden...",
  "Alarm_Detail_Log_NewMessagesCallToAction": {
    "one": "Er is een nieuw bericht beschikbaar",
    "other": "Er zijn nieuwe berichten beschikbaar"
  },
  "Alarm_Acknowledged": "Alarm is afgemeld",
  "Menu_Linked_Placeholder": "Selecteer een klant",
  "Menu_Updates": "Wat is er nieuw?",
  "Languages": {
    "English": "English",
    "Dutch": "Nederlands",
    "French": "Frans"
  },
  "Login": {
    "Placeholders": {
      "Username": "Gebruikersnaam",
      "Password": "Wachtwoord"
    },
    "Failed": "De logingegevens waren incorrect",
    "Query_Token": {
      "Pending": "Aan het inloggen...",
      "Success": "U bent nu ingelogd",
      "Error": "Inloggen mislukt"
    },
    "Message": {
      "TokenExpired": "De sessie is verlopen, gelieve opnieuw in te loggen.",
      "TokenInvalid": "Ongeldige sessie, gelieve opnieuw in te loggen."
    }
  },
  "Alarm_Detail_Contact": {
    "Push_Message": {
      "Subtitle": "De alarmdetails worden gedeeld via een pushmelding.",
      "Title": "Pushmelding"
    },
    "Automatic_Phone_Message": {
      "Subtitle": "De alarmdetails worden gedeeld via een automatisch telefoongesprek en SMS-bericht.",
      "Title": "Automatisch telefonisch bericht"
    },
    "Phone_Call": {
      "Title": "Zelf bellen",
      "Subtitle": "Bel de persoon rechtstreeks.",
      "Done": "Klaar",
      "Instructions": "Bel het nummer hieronder. Klik om te kopiëren of bellen.\n\n(Bel tot max. 2 keer totdat je iemand hebt gesproken.)",
      "Result": {
        "Responding": "Persoon gaat helpen",
        "Not_Reachable": "Persoon gaat NIET helpen",
        "No_Response": "Persoon kon niet worden bereikt",
        "Title": "Hoe ging het bellen?",
        "Skipped": "Annuleer het bellen",
        "Wrong_Number": "Telefoonnummer is ongeldig"
      },
      "AlarmCreator": {
        "Result": {
          "Responding": "Alarmmaker heeft geen hulp nodig",
          "Not_Reachable": "Alarmmaker heeft hulp nodig",
          "No_Response": "Geen contact / onbereikbaar"
        }
      }
    },
    "Sms_Message": {
      "Title": "SMS-bericht",
      "Subtitle": "De alarmdetails worden gedeeld via een SMS-bericht.",
      "Output": {
        "Title": "Voorbeeld van de SMS"
      },
      "Toast": {
        "Error": "Er ging iets mis bij het verzenden van de sms",
        "Sending": "Sms verzenden...",
        "Sent": "Sms-bericht verzonden"
      }
    },
    "Email": {
      "Title": "Schrijf een e-mail",
      "Subtitle": {
        "one": "De alarmdetails worden gedeeld via een e-mail.",
        "other": "De alarmdetails worden gedeeld via een e-mail."
      },
      "Output": {
        "Title": "Voorbeeld van het bericht"
      },
      "Subject": "X-Guard - Informatie over een alarm",
      "Toast": {
        "Sending": "Bezig met verzenden...",
        "Sent": "E-mail verzonden!",
        "Error": "E-mail verzenden mislukt"
      }
    },
    "Title": "%{name} contacteren",
    "Builder_Form": {
      "Add_Data": {
        "Title": "Alarmdetails toevoegen aan dit bericht",
        "Fields": {
          "Alarm_Position": "Alarmpositie",
          "Personal_Information": "Persoonlijke informatie",
          "Include_Contact_Code": "Meldcode / contractcode",
          "Name": "Naam",
          "Current_Position": "Huidige positie"
        }
      },
      "Message": {
        "Title": "Typ een bericht",
        "Subtitle": "Wilt u dat deze persoon u terug kan bellen? Voer dan hier uw telefoonnummer in met terugbelinstructies.",
        "Placeholder": "Schrijf u bericht kort, maar duidelijk.",
        "Choose_Template": "Open een sjabloon"
      },
      "Send": "Verzenden"
    }
  },
  "Alarm_Detail_Acknowledge": {
    "Title": "Actief alarm",
    "Subtitle": "Meld dit alarm niet af tot er hulp is geboden en de situatie is opgelost. Bij het afmelden wordt de communicatie over dit alarm afgebroken.",
    "Button_Label": "Alarm afmelden",
    "Survey": {
      "Title": "Afmeldonderzoek",
      "customerHappy": {
        "Title": "Was de klant tevreden met de afhandeling van dit alarm?"
      },
      "Item": {
        "yes": "Ja",
        "no": "Nee",
        "unknown": "Onbekend",
        "notApplicable": "n.v.t.",
        "other": "Anders",
        "skipped": "Overslaan",
        "noAudioConnection": "Geen audioverbinding aangeboden",
        "noSoundOnConnection": "Er was een gesprek, maar ik kon niets horen",
        "noHumanResponse": "Er was een gesprek en geluid, maar dit was onverstaanbaar",
        "notAccurate": "Lage GPS-nauwkeurigheid",
        "notAvailable": "Er was geen positionele informatie",
        "incorrectAddress": "Het getoonde adres was incorrect",
        "notUpToDate": "De locatie was niet recent (oude locatie)"
      },
      "audioQualityOk": {
        "Title": "Werkte het telefoongesprek / de luisterverbinding?"
      },
      "audioQualityFeedback": {
        "Title": "Wat ging er mis met het telefoongesprek / de luisterverbinding?"
      },
      "responseOk": {
        "Title": "Was de opvolging op de juiste manier ingesteld?"
      },
      "responseFeedback": {
        "Title": "Beschrijf kort wat er niet klopte aan de opgegeven opvolging.",
        "Placeholder": "Houd het kort en krachtig.",
        "Label": "Doorgaan"
      },
      "positionOk": {
        "Title": "Was de positionele informatie correct?"
      },
      "positionFeedback": {
        "Title": "Geef aan wat er mis was met de positionele informatie"
      },
      "comment": {
        "Title": "Afsluitend / overig commentaar bij afmelden alarm",
        "Placeholder": "Vat het alarm samen",
        "Label": "Alarm afmelden"
      }
    },
    "Comment": {
      "customerHappy": "**Alarmmaker tevreden?**\n%{message}",
      "audioQualityOk": "**Audiogesprek OK**\n%{message}",
      "audioQualityFeedback": "**Audiogesprek (feedback)**\n%{message}",
      "responseOk": "**Opvolging OK**\n%{message}",
      "responseFeedback": "**Opvolging (feedback)**\n%{message}",
      "positionFeedback": "**Positie (feedback)**\n%{message}",
      "positionOk": "**Positie OK**\n%{message}",
      "comment": "**Commentaar**\n%{message}"
    },
    "Toast": {
      "Pending": "Aan het afmelden...",
      "Success": "Alarm succesvol afgemeld",
      "Error": "Alarm afmelden mislukt"
    },
    "Blocked": "Dit alarm is geëscaleerd naar '%{name}'. Een geëscaleerd alarm kan niet worden afgemeld door jou."
  },
  "Menu_Linked_No_Customers_Found": "Geen klanten gevonden",
  "Alarm_Detail_Response_Protocol_Panic": "- NIET SPREKEN!\n- Verbind met het telefoongesprek\n- Luister het gesprek uit (30 seconden)\n- Als er hulp nodig is, klik op 'HELP' hier onder\n- Vervolg met het belprotocol op volgorde zoals hieronder aangegeven",
  "Alarm_Detail_Response_Protocol_Assistance": "- Verbind met het telefoongesprek\n- Spreek direct: 'Alarmcentrale, hoe kan ik u helpen?'\n- Als er hulp nodig is, klik op 'HELP' hier onder\n- Vervolg met het belprotocol op volgorde zoals hieronder aangegeven",
  "Alarm_Detail_Response_Protocol_Test": "Er is een 'groen alarm' gemaakt. Meestal is er verdere geen actie nodig voor de afhandeling van dit alarm.\nControleer de naam en oorsprong van dit alarm en beslis of er actie genomen moet worden.",
  "Alarm_Detail_Response_Procedure": {
    "Priority": {
      "Verification": "Verificatie",
      "Help": "HELP"
    }
  },
  "Alarm_Detail_Response": {
    "Contact": {
      "Label": "Contacteren",
      "Call": {
        "Label": "Klik voor bellen"
      }
    },
    "Section": {
      "AlarmCreator": {
        "Heading": "Alarmmaker"
      }
    },
    "Type": {
      "ACR_QR": {
        "Title": "Mensen dichtbij",
        "Subtitle": "Gesorteerd op afstand"
      },
      "ACR_NQR": {
        "Title": "Mobiele surveillanten",
        "Subtitle": "Gesorteerd op afstand"
      },
      "ACR_RA": {
        "Title": "Gebied",
        "Subtitle": "Postcode %{code}"
      },
      "ACR_PD": {
        "Title": "Politie"
      },
      "ACR_PC": {
        "Title": "Persoonlijke contacten"
      },
      "ACR_APR": {
        "Title": "Mobiele surveillant"
      }
    },
    "Missing": "Opvolging is verborgen",
    "Partly_Missing": "Er zijn delen van de opvolging verborgen of missend.",
    "APR": {
      "start": "Mobiele Surveillant oproepen",
      "cancel": "Annuleren",
      "Info": {
        "Ready": {
          "Main": "Mobiele surveillant beschikbaar",
          "Sub": "We roepen automatisch een mobiele surveillant op"
        },
        "InProgress": {
          "Main": "Op zoek naar een mobiele surveillant"
        },
        "Finding": "Mobiele surveillant wordt opgeroepen:",
        "Found": "Toegewezen mobiele surveillant:",
        "Rejected": "%{name} is niet beschikbaar"
      },
      "Step": [
        {
          "InProgress": "Uitgenodigd",
          "Done": "Geaccepteerd"
        },
        {
          "InProgress": "Onderweg",
          "Done": "Op locatie"
        },
        {
          "InProgress": "Op locatie",
          "Done": "Afgerond"
        }
      ],
      "Invited": "**Gedeelde informatie**\n- Wie heeft het alarm gemaakt?\n- Actuele positie (wordt live bijgewerkt)\n\n**Contact met de mobiele surveillant is beschikbaar na acceptatie**",
      "Accepted": "**Gedeelde informatie**\n- Wie heeft het alarm gemaakt?\n- Actuele positie (wordt live bijgewerkt)\n\n**Contact de mobiele surveillant**\n1. Bel [%{formattedPhoneNumber}](tel:%{phoneNumber})\n2. Leg de situatie uit\n3. Deel uw terugbelnummer",
      "OnLocation": "**Gedeelde informatie**\n- Wie heeft het alarm gemaakt?\n- Actuele positie (wordt live bijgewerkt)\n\n**Contacteer de mobiele surveillant**\n1. Bel [%{formattedPhoneNumber}](tel:%{phoneNumber})\n2. Leg de situatie uit\n3. Deel uw terugbelnummer",
      "Finished": "Afgerond",
      "FindingReplacement": "Andere mobiele surveillant uitnodigen...",
      "restart": "Mobiele Surveillant oproepen",
      "Explanation": "Uitleg",
      "Overlay": {
        "Confirm": "Bevestigen",
        "Cancel": "Annuleren",
        "Confirmation": {
          "start": "Weet u zeker dat u een mobiele surveillant wilt oproepen?",
          "cancel": "Weet u zeker dat u de mobiele surveillant wilt annuleren?",
          "restart": "Weet u zeker dat u opnieuw een mobiele surveillant wil oproepen?"
        },
        "Title": "Mobiele Surveillant"
      },
      "Replaced_By_Zone": {
        "Sub": "Het systeem is er niet in geslaagd om automatisch een mobiele surveillant toe te wijzen. Vervolg met de bellijst.",
        "Title": "Inzet mobiele surveillant beëindigd"
      },
      "Comment": "Bijgevoegd commentaar: %{comment}",
      "ImagePreviews": {
        "Title": "Door de surveillant gemaakte foto's"
      }
    }
  },
  "Alarm_Detail_Map": {
    "Marker": {
      "Current_Position": {
        "Label": "Huidige positie (leidend)"
      },
      "Alarm_Position": {
        "Label": "Alarmpositie"
      }
    },
    "AutoZoom": "Automatisch inzoomen",
    "ImageOverlays": "Plattegronden"
  },
  "EnvBanner": {
    "localhost": "Verbonden met localhost",
    "staging": "Verbonden met testomgeving"
  },
  "Alarm_Overview_List": {
    "Closed_Alarms": {
      "No_Results": {
        "With_Filters": "Er zijn geen afgemelde alarmen binnen je zoekopdracht",
        "No_Filters": "Er zijn geen afgemelde alarmen"
      }
    },
    "Open_Alarms": {
      "No_Results": {
        "With_Filters": "Er zijn geen open alarmen binnen je zoekopdracht",
        "No_Filters": "Er zijn geen open alarmen"
      }
    },
    "Filtered": {
      "Count_Visible": "%{filteredCount} van de %{unfilteredCount} alarmen worden getoond",
      "Remove_Filters": "Filters wissen"
    }
  },
  "Footer_TermsAndConditions": "Algemene voorwaarden",
  "Footer_Disclaimer": "Disclaimer",
  "Footer_Privacy": "Privacy",
  "AppVersion": "X-Guard Alarm Manager V%{semVer}",
  "Footer_Status": "Status",
  "Permission": {
    "Notification": {
      "Not_Granted": "Je ontvangt geen browsernotificaties omdat de permissie niet is toegestaan."
    }
  },
  "Notification": {
    "New_Alarm": {
      "Panic": "Nieuw alarm (rood)",
      "Assistance": "Nieuw alarm (oranje)",
      "Test": "Nieuw alarm (groen)"
    },
    "Multiple_New_Alarms": "Er zijn meerdere actieve alarmen"
  },
  "Alarm_Detail_DCC": {
    "Disconnected": {
      "Title": "Gesprek is actief",
      "Tooltip": "Voer deze code op de telefoon in om verbinding te maken.",
      "Mute_On_Join": "Demp mijn geluid bij deelname"
    },
    "Connected": {
      "Title": "Je neemt deel aan het gesprek",
      "Incoming_Call_From": "Inkomend gesprek van",
      "Connected_To": "Verbonden personen",
      "Microphone": {
        "Label": "Je eigen microfoon is",
        "Muted": "Gedempt",
        "Enabled": "Actief"
      },
      "Self": "Jij",
      "Alarm_Maker": "Alarmmaker"
    },
    "Not_Created": "Er is geen actief telefoongesprek. Volg het protocol hieronder. Noem dit niet aan de maker van het alarm.",
    "Destroyed": "Telefoongesprek beëindigd. Ga door met de procedure zoals hieronder beschreven.",
    "Recording": {
      "Title": "Audiofragment beschikbaar: mogelijk met nieuwe informatie!",
      "Instruction": "Luister de eerste seconden na het alarm terug en beoordeel of hulp nodig is op basis van dit fragment.\n\nKlik op de 'Help'-knop hieronder wanneer je hoort dat er hulp nodig is."
    }
  },
  "Alarm_Detail_Log_Action_Call_Result_Responding": "%{name} komt helpen",
  "Alarm_Detail_Log_Action_Call_Result_Not_Reachable": "%{name} kan niet komen helpen",
  "Alarm_Detail_Log_Action_Call_Result_No_Response": "%{name} kon niet telefonisch worden bereikt",
  "Alarm_Detail_Log_Action_Call_Result_Cancelled": "Bellen naar %{name} geannuleerd",
  "Unsupported_Device_Popup": {
    "Title": {
      "Mobile_Device": "Smartphone gedetecteerd",
      "Resolution": "Lage browserresolutie gedetecteerd"
    },
    "Body": {
      "Mobile_Device": "Deze website is niet geschikt voor mobiele telefoons. Open de Alarm Manager op een desktop of bekijk alarmen via de X-Guard Alarm-app.",
      "Resolution": "Deze browserresolutie wordt niet officieel ondersteund. Er kunnen problemen optreden."
    },
    "Continue": "Doorgaan"
  },
  "Alarm_Detail_Log_Action_Email": "E-mail gestuurd naar %{name}",
  "Alarm_Detail_Log_Action_Protocol_Help": "Gewisseld naar 'HELP'-protocol.",
  "Alarm_Detail_Log_Action_Protocol_Verification": "Gewissel naar 'Verificatie'-protocol",
  "Address_Accuracy": "%{accuracy} nauwkeurig",
  "Address_Unknown": "Locatie onbekend",
  "Main_HiddenAlarms": "Verwijder zoekfilter om nieuw binnengekomen alarmen weer te geven.",
  "Error_Validation_NotAValidNumber": "Geen geldig getal",
  "Alarm": {
    "Date_Created": "Alarmdatum"
  },
  "Address_View": "Bekijk adres via Google Maps",
  "Alarm_Detail_Chat": {
    "AlarmCreator": "Maker van het alarm"
  },
  "Alarm_Detail_OldAlarmManager": {
    "Title1": "Dit alarm is (tijdelijk) ook zichtbaar via de oude Alarm Manager.",
    "Title2": "Klik hier om dit alarm te openen."
  },
  "Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Responding": "%{name} heeft geen hulp nodig",
  "Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Not_Reachable": "%{name} heeft hulp nodig",
  "Distance_Label": {
    "Meters": "%{distance} meter",
    "KiloMeters": "%{distance} km"
  },
  "Alarm_Detail_Log_Action_Alarm_Call_Initiated": "Alarmgesprek geïnitieerd (wachten op deelnemers)",
  "Alarm_Detail_Log_Action_Alarm_Call_Join": "Verbonden met alarmgesprek",
  "Alarm_Detail_Log_Action_Alarm_Call_Leave": "Verbinding met alarmgesprek verbroken",
  "Alarm_Detail_Log_Action_Alarm_Call_Start": "Alarmgesprek gestart",
  "Alarm_Detail_Log_Action_Alarm_Call_Ended": "Alarmgesprek gestopt",
  "Alarm_Detail_Log_Action_Group_Call_Join": "Verbonden met responder-groepsgesprek",
  "Alarm_Detail_Log_Action_Group_Call_Leave": "Verbinding met responder-groepsgesprek verbroken",
  "DateTimePickerField": {
    "granularity": {
      "days": "Datum",
      "hours": "Tijd"
    },
    "AmPm": {
      "am": "AM",
      "pm": "PM"
    }
  },
  "Report_Code": "Meldcode: %{code}",
  "Alarm_Detail_Helpers": {
    "Missing": "Lijst met helpers is verborgen"
  },
  "Login_SavedUsers_NeverLoggedIn": "Nog nooit ingelogd",
  "Login_SavedUsers_ConfirmRemoveToken": "Weet je zeker dat je deze sessie wilt verwijderen?",
  "Menu_OldAlarmManager": "Klik hier voor de oude Alarm Manager",
  "Alarm_Detail_Log_NewMessagesNotification": {
    "one": "Nieuw bericht toegevoegd",
    "other": "%{count} nieuwe berichten"
  },
  "AlarmDetails_Description": "Extra informatie",
  "Alarm_Detail_Log_Action_Call_Result_Wrong_Number": "Telefoonnummer van %{name} is incorrect",
  "ErrorDisplay": {
    "Title": {
      "Error": "Er ging iets mis",
      "Retrying": "Er ging iets mis, we probreren het opnieuw.."
    }
  },
  "Alarm_Overview_XgacAlarmCount": "Open alarmen - Klik hier om te openen",
  "Alarm_Overview_XgacAlarmCount_Sub": "Uw aandacht is vereist",
  "Alarm_Detail_Log_Action_Alarm_Merged": "De alarmknop is nogmaals ingedrukt door de alarmmaker.\n\nHet nieuwe alarm is automatisch samengevoegd met dit alarm.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Red": "Het alarmniveau is geëscaleerd van groen naar rood.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Orange": "Het alarmniveau is geëscaleerd van groen naar oranje.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Orange_Red": "Het alarmniveau is geëscaleerd van oranje naar rood.",
  "Alarm_Detail_Log_Action_Alarm_Republished": "Het escalatieproces is opnieuw gestart",
  "Alarm_Detail_Merge_History": {
    "counter": {
      "subText": "een alarm gemaakt"
    },
    "title": "Dit alarm is %{count} keer aangemaakt",
    "showAllAlarms": {
      "show": {
        "other": "Toon nog %{count} alarmen",
        "one": "Toon nog 1 alarm"
      },
      "hide": {
        "other": "Verberg %{count} alarmen",
        "one": "Verberg 1 alarm"
      }
    }
  },
  "Helpers_Anonymous": "Anoniem",
  "Login_SavedUsers_TokenExpired": "Teveel tijd sinds laatste activiteit, of gebruiker is verwijderd. Probeer nogmaals in te loggen",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Failed": "Kan de mobiele surveillant niet annuleren",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Requested": "Verzoek om mobiele surveillant %{name} te annuleren",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Accepted": "Mobiele surveillant %{name} heeft geaccepteerd",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Invited": "Mobiele surveillant %{name} uitgenodigd",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Started": "Mobiele surveillant %{name} is gestart",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_OnLocation": "Mobiele surveillant %{name} is op locatie gearriveerd",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Finished": "Mobiele surveillant %{name} is klaar",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Error": "Mobiele surveillant %{name} gaf een technische foutmelding",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancelled": "Mobiele surveillant %{name} heeft geannuleerd",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Rejected": "Mobiele surveillant %{name} heeft afgewezen"
} as const

export default language